<template>
  <div id="app">
    <router-view />
    <vip-msg />
    <!-- <vip-msg-expire /> -->
  </div>
</template>

<script>
import { getQueryVariable } from '@/utils'

import VipMsg from '@/components/vipMsg/vip-msg.vue'
// import VipMsgExpire from '@/components/vipMsg/vip-msg-expire.vue'

export default {
  components: {
    VipMsg,
    // VipMsgExpire,
  },
  created() {
    this.initState()
  },
  methods: {
    initState() {
      const clientId = getQueryVariable('clientId')
      const token = getQueryVariable('token')
      if (clientId && token) {
        this.$store.commit('setIsFromFriend', true)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/element.scss';
</style>
