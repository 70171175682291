<template>
  <el-dialog title="会员到期提醒" :visible.sync="dialogVisible" width="500px" :before-close="closeDialogBefore"
    :close-on-click-modal="false" class="vip-msg-box">
    <div class="vip-msg-content-box">
      <svg-icon icon-class="mark" class="f36 mb10" />
      <div class="txt">
        <p>亲，您的会员即将到期！</p>
        <p>到期后将自动扣费，请确保您的账户积分充足，谢谢！</p>
        <p>非会员将无法查看买家账号少量关键数据。</p>
        <!-- <p>亲，您好！您的会员即将到期自动扣费，</p>
        <p>请保证您的账户余额充足，谢谢！</p> -->
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="the-left">
        <el-checkbox v-model="noRemind">不再提醒</el-checkbox>
      </div>
      <div class="the-right">
        <el-button round type="primary" class="kxx-button" @click="gotIt()">
          去充值积分
          <!-- 好的，我知道了 -->
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

import {
  getToday,
} from '@/utils'
import {
  setItem,
} from '@/utils/storage.js'

import { userConfig } from '@/api/home.js'

export default {
  name: 'vip-msg',
  components: {},
  props: {
  },
  data() {
    return {
      noRemind: false, // 7天内都不提醒
    }
  },
  computed: {
    ...mapState({
      dialogVisible: state => state.vipDayMsg,
    }),
  },
  methods: {
    setRechargeNoticeFlag() {
      userConfig({ configKey: 'recharge_notice_flag', configValue: 'true' })
    },
    closeDialogBefore() {
      const { noRemind } = this
      this.$store.commit('setVipDayMsg', false)
      const today = getToday()
      if (noRemind) {
        this.setRechargeNoticeFlag()
      }
      setItem('vipDayCurDate', today)
    },
    gotIt() {
      this.closeDialogBefore()
    },
    goToActivity() {
      this.closeDialogBefore()
      this.$router.push('/activityTiger')
    },
  }
}
</script>

<style scoped lang="scss">
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vip-msg-content-box {
  text-align: center;
  line-height: 26px;
  font-size: 14px;
}
</style>
