// 请求封装
import axios from 'axios'
import { clear } from '@/utils/storage.js'
import router from '@/router/'
import { Message } from 'element-ui'
import store from '../store'

const request = axios.create({
  baseURL: process.env.VUE_APP_HOST, // 基础路径
  withCredentials: true
  // timeout: 10000 // 请求超时时间
})

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    config.url = decodeURI(encodeURI(config.url).replace(/%E2%80%8B/g, ''))
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  function (response) {
    if (response.data.errorCode == 'session.not.found') {
      if (store.getters.isFromFriend) {
        return response.data
      }
      Message.closeAll()
      Message({
        message: '用户信息过期，请重新登录',
        type: 'warning'
      })
      clear()
      var date = new Date()
      date.setTime(date.getTime() - 10000)
      document.cookie = 'fst=a; expires=' + date.toGMTString()
      router.replace({
        name: 'Home',
        params: { logo: 1 }
      })
      store.commit('loginDV', true)
    } else {
      if (!response.config.noBallError) {
      if (!response.data.success) {
        Message.closeAll()
        Message({
          message: response.data.message,
          type: 'error'
        })
      }
      }
    }
    // 响应成功进入这里
    return response.data
  },
  error => {
    // 请求响应失败进入这里
    // const status = error.response.status
    // console.log(error,'返回的状态码');
    // 抛出异常
    return Promise.reject(error)
  }
)

export default request
