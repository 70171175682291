import Vue from "vue";
import VueRouter from "vue-router";
import My from "./modules/my"
import { getItem } from '../utils/storage'
import store from '@/store'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home/index.vue"),
    meta: {}
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("@/views/my/index.vue"),
    redirect: "/user-info",
    children: My
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/views/auth/index.vue")
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    component: () => import("@/views/update/index.vue")
  },
  {
    path: "/invite-activity",
    name: "InviteActivity",
    component: () => import("@/views/activity/index.vue")
  },
  {
    path: "/artcle-page",
    name: "artclePage",
    component: () => import("@/views/artcle/index.vue")
  },
  {
    path: "/artcle",
    name: "artcleDetail",
    component: () => import("@/views/artcle/artcle-detail.vue")
  },
  {
    path: "/course-page",
    name: "coursePage",
    component: () => import("@/views/course/index.vue")
  },
  {
    path: "/course",
    name: "courseDetail",
    component: () => import("@/views/course/course-detail.vue")
  },
  {
    path: "/client",
    name: "clientPage",
    component: () => import("@/views/client/index.vue")
  },
  {
    path: '/andu',
    name: 'andu',
    component: () => import('@/views/pcad/index.vue'),
  },
  {
    path: '/toolcabinet',
    name: 'toolCabinet',
    component: () => import('@/views/toolCabinet/index.vue'),
  },
  {
    path: "/regard",
    name: "regard",
    component: () => import("@/views/regard/index.vue")
  },
  {
    path: '/activityTiger',
    name: 'activityTiger',
    component: () => import('@/views/activity/tiger.vue'),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

// 解决多次点击跳转同一个路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return originalReplace.call(this, to).catch(err => err)
}

let queryParams = null

router.beforeEach((to, from, next) => {

  if (
    to.path === '/home'
    && store.getters.isFromFriend
    && queryParams
  ) {
    let query = {
      ...to.query,
      ...queryParams,
    }
    queryParams = null
    next({
      path: to.path,
      query,
    })
    return
  }
  if (from.path === '/home') {
    const { token, clientId } = from.query
    if (token && clientId) {
      queryParams = { token, clientId }
    }
  }
  if (to.path !== '/home') {
    // 如果已登录，则直接通过
    if (getItem('userInfo')) {
      return next()
    } else {
      const noForceHomeArr = [
        '/auth', '/update-password', '/artcle-page', '/artcle', '/course-page',
        '/course', '/client', '/test', '/andu', '/toolCabinet', '/regard',
        '/activityTiger',
      ]
      if (!noForceHomeArr.includes(to.path)) {
        router.replace({
          path: '/home'
        })
      }
      next()
    }
  } else {
    next()
  }
})

export default router;
