import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    vipDayMsg: false, // 会员到期天数是否显示
    vipExpireMsg: false, // 会员到期提示
    vipIntegralMsg: false, // 会员未到期，无积分
    isFromFriend: false,
    loginVuex: false
  },
  mutations: {
    setUserInfo (state, data) {
      if (data) { 
        state.userInfo = { ...data }
      }
    },
    setVipIntegralMsg (state, data) {
      state.vipIntegralMsg = data
    },
    setVipExpireMsg (state, data) {
      state.vipExpireMsg = data
    },
    setVipDayMsg(state, data) {
      state.vipDayMsg = data
    },
    setIsFromFriend (state, data) {
      state.isFromFriend = data
    },
    loginDV (state, data) {
      state.loginVuex = data
    }
  },
  getters: {
    userInfo: state => state.userInfo, // 用户信息
    vipDayMsg: state => state.vipDayMsg,
    vipExpireMsg: state => state.vipExpireMsg,
    vipIntegralMsg: state => state.vipIntegralMsg,
    isFromFriend: state => state.isFromFriend,
  },
  actions: {},
  modules: {}
})
