// 我的账户
export default [
  {
    path: "/user-info",
    name: "UserInfo",
    component: () => import(`@/views/my/user-info.vue`),
    meta: {
      title: "个人信息"
    }
  },
  {
    path: "/recharge",
    name: "Recharge",
    component: () => import(`@/views/my/recharge.vue`),
    meta: {
      title: "充值"
    }
  },
  {
    path: "/recharge-record",
    name: "RechargeRecord",
    component: () => import(`@/views/my/recharge-record.vue`),
    meta: {
      title: "充值记录"
    }
  },
  {
    path: "/query-record",
    name: "QueryRecord",
    component: () => import(`@/views/my/query-record.vue`),
    meta: {
      title: "查询记录"
    }
  },
  {
    path: "/use-record",
    name: "UseRecord",
    component: () => import(`@/views/my/use-record.vue`),
    meta: {
      title: "消耗记录"
    }
  },
  {
    path: "/invite-record",
    name: "InviteRecord",
    component: () => import(`@/views/my/invite-record.vue`),
    meta: {
      title: "邀请记录"
    }
  }
]
