// 首页内容相关模块
import request from '@/utils/request'

// 首页查询(收费Profile)
export const getSearchProfileId = params => {
  return request({
    method: 'GET',
    url: '/api/search/get',
    noBallError: true,
    params
  })
}

// 首页查询(收费name+Helpful Votes+Reviews)
export const searchQuery = data => {
  return request({
    method: 'POST',
    url: '/api/search/query',
    data
  })
}

// 提交举报内容(免费)
export const reportSubmit = data => {
  return request({
    method: 'POST',
    url: `/api/report/submit?captcha=${data.captcha}`,
    data
  })
}

// 获取举报类型列表(免费)
export const reportTypes = params => {
  return request({
    method: 'GET',
    url: '/api/report/types',
    params
  })
}

// 首页查询(举报汇总)
export const reportSummary = params => {
  return request({
    method: 'GET',
    url: '/api/report/view/summary',
    params
  })
}

// 首页查询(案例的)
export const searchSample = params => {
  return request({
    method: 'GET',
    url: '/api/search/sample',
    params,
  })
}

// 首页查询(免费)
export const reportSearch = params => {
  return request({
    method: 'GET',
    url: '/api/report/search',
    params
  })
}

// 首页示例
export const searchSamples = params => {
  return request({
    method: 'GET',
    url: '​/api/search/samples',
    params,
  })
}

// 导入查询
export const importQuery = () => {
  return request({
    method: 'GET',
    url: '​/api/search/importQuery'
  })
}

// 搜索提示
export const queryUserCoin = data => {
  return request({
    method: 'POST',
    url: '​/api/user/queryUserCoin',
    data
  })
}

// 是否展示弹框
export const userConfig = data => {
  return request({
    method: 'POST',
    url: '​/api/userconfig/change',
    data
  })
}

// 批量导入进度
export const importProcess = params => {
  return request({
    method: 'GET',
    url: `/api/process/${params}`
  })
}

// 批量重新查询
export const batchSearchRepeat = params => {
  return request({
    method: 'POST',
    url: `/api/order/batchSearchRepeat`,
    params
  })
}

// 点击下载导入表格
export const downloadImportQuery = params => {
  return request({
    method: 'GET',
    url: `/api/order/downloadImportQuery`,
    params
  })
}

// 举报滚动部分内容
export const exhibitAccount = params => {
  return request({
    method: 'GET',
    url: '/api/report/view/exhibitAccount',
    params
  })
}

// 昨日数据
export const yesterdayReport = params => {
  return request({
    method: 'GET',
    url: '/api/report/view/yesterdayReport',
    params
  })
}

export const searchAccounts = params => {
  return request({
    method: 'GET',
    url: '/api/search/accounts',
    params
  })
}

export const searchAccountSearch = params => {
  return request({
    method: 'GET',
    url: '/api/search/accountSearch',
    params
  })
}

// 下载举报模板
export const reportTemplate = params => {
  return request({
    method: 'GET',
    url: '/api/report/template',
    params
  })
}

// 批量举报
export const batchReport = data => {
  return request({
    method: 'POST',
    url: '​/api/report/importSubmit',
    data
  })
}

// banner图册
export const getBanners = params => {
  return request({
    method: 'GET',
    url: '​/api/common/banners',
    params
  })
}

// 从外部跳转过来查询
export const encOrder = params => {
  return request({
    method: 'GET',
    url: '/api​/order/encOrder',
    params
  })
}


// 记录用户点击的次数
export const statUser = data => {
  return request({
    method: 'POST',
    url: '/api​/stat/user',
    data
  })
}

// 记录引流暗度的次数
export const records = data => {
  return request({
    method: 'POST',
    url: '/api​/stat/record',
    data
  })
}

//站点列表数据
export const countryer = useId => {
  return request({
    method: 'GET',
    url: '/api/search/country',
    useId
  })
}

//是否已经登录
export const isLogin = useId => {
  return request({
    method: 'GET',
    url: '/api/user/isLogin',
    useId
  })
}

// 获取当前登录用户的登录信息
export const tokenInfo = useId => {
  return request({
    method: 'GET',
    url: '/api/user/tokenInfo',
    useId
  })
}

// 判断查询top详情是否免费查询
export const isFreeTopDetailQuery = params => {
  return request({
    method: 'GET',
    url: '/api/search/isFreeTopDetailQuery',
    params
  })
}

export const showTopDetail = data => {
  return request({
    method: 'POST',
    url: '/api/search/showTopDetail',
    data
  })
}

