import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from 'vue-i18n'

import './utils/element.js'

import './styles/style.css';
import './styles/style.scss';
import './styles/course.scss';

import './icons'
import IconNew from '@/components/IconNew.vue'

Vue.component('IconNew', IconNew)
Vue.prototype.$eventBus = new Vue()

import '@/utils/directives';


Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./lang/zh'),   // 中文语言包（zh-CN）
    'en-US': require('./lang/en')    // 英文语言包（en-US）
  }
})

// {{$t('en.home')}}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
