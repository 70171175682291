import Vue from 'vue'

import {
  Tabs,
  TabPane,
  Button, Select, Option, Menu, Submenu, MenuItem, Input, Cascader, Table, TableColumn, Dialog, Dropdown, DropdownMenu, DropdownItem, Steps, Step, Form, FormItem, DatePicker, Tag, CheckboxGroup, Checkbox, Upload, Message, Pagination, Loading, MessageBox, Popover, Radio, RadioGroup, Carousel, CarouselItem
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;

[ Tabs,
  TabPane,
  Button, Select, Option, Menu, Submenu, MenuItem, Input, Cascader, Table, TableColumn, Dialog, Dropdown, DropdownMenu, DropdownItem, Steps, Step, Form, FormItem, DatePicker, Tag, CheckboxGroup, Checkbox, Upload, Pagination, Loading, Loading.directive, Popover, Radio, RadioGroup, Carousel, CarouselItem].forEach(item => {
    Vue.use(item)
  })