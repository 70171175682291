import Vue from 'vue'

// 全局指令 用于防止按钮重复点击
// disabled属性仅作用于button div span无效
Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  },
})

// 用于防止按钮重复点击 可作用于div
Vue.directive('pointerReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      el.style.pointerEvents = 'none'
      setTimeout(() => {
        el.style.pointerEvents = 'auto'
      }, binding.value || 1000)
    })
  },
})
